export default {
  shows: [
    {
      date: "2024-07-13",
      country: "SWE",
      city: "Mariefred",
      state: null,
      venue: "Kalkfest Festival",
    },
    {
      date: "2019-03-01",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Scala Theater",
    },
    {
      date: "2017-07-29",
      country: "SWE",
      city: "Eskilstuna",
      state: null,
      venue: "Torshälla 700 Festival",
    },
    {
      date: "2014-12-11",
      country: "SWE",
      city: "Uppsala",
      state: null,
      venue: "Kalmar Nation",
    },
    {
      date: "2012-05-03",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Scandic Grand Central",
    },
    {
      date: "2011-12-13",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Musslan",
    },
    {
      date: "2011-11-11",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Wirströms",
    },
    {
      date: "2011-08-26",
      country: "SWE",
      city: "Borlänge",
      state: null,
      venue: "Cafe Peace&Love",
    },
    {
      date: "2011-08-25",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Trädgården",
    },
    {
      date: "2011-07-02",
      country: "SWE",
      city: "Borlänge",
      state: null,
      venue: "Peace&Love Festival",
    },
    {
      date: "2011-02-16",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Musslan",
    },
    {
      date: "2011-02-14",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Hjerta",
    },
    {
      date: "2010-10-30",
      country: "SWE",
      city: "Eskilstuna",
      state: null,
      venue: "Stadsteatern",
    },
    {
      date: "2010-10-18",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Lilla Hotellbaren",
    },
    {
      date: "2010-10-14",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Rocket FM Radio",
    },
    {
      date: "2010-09-21",
      country: "US",
      city: "Los Angeles",
      state: "CA",
      venue: "Silverlake Lounge",
    },
    {
      date: "2010-09-12",
      country: "US",
      city: "Fairfax",
      state: "CA",
      venue: "19 Broadway",
    },
    {
      date: "2010-09-10",
      country: "US",
      city: "San Francisco",
      state: "CA",
      venue: "Brainwash",
    },
    {
      date: "2010-07-03",
      country: "SWE",
      city: "Borlänge",
      state: null,
      venue: "Peace&Love @ Gaffa",
    },
    {
      date: "2010-07-03",
      country: "SWE",
      city: "Borlänge",
      state: null,
      venue: "Peace&Love @ Cozmos",
    },
    {
      date: "2010-07-03",
      country: "SWE",
      city: "Borlänge",
      state: null,
      venue: "Peace&Love @ Cafe Peace&Love",
    },
    {
      date: "2010-07-02",
      country: "SWE",
      city: "Leksand",
      state: null,
      venue: "Skutan",
    },
    {
      date: "2010-06-08",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Musslan",
    },
    {
      date: "2009-12-26",
      country: "SWE",
      city: "Eskilstuna",
      state: null,
      venue: "Raw",
    },
    {
      date: "2009-11-04",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Södra Teatern",
    },
    {
      date: "2009-11-03",
      country: "FIN",
      city: "Helsinki",
      state: null,
      venue: "Semifinal",
    },
    {
      date: "2009-11-03",
      country: "FIN",
      city: "Helsinki",
      state: null,
      venue: "Radio Helsinki",
    },
    {
      date: "2009-10-29",
      country: "US",
      city: "Los Angeles",
      state: "CA",
      venue: "The Mint",
    },
    {
      date: "2009-10-28",
      country: "US",
      city: "Los Angeles",
      state: "CA",
      venue: "Room 5",
    },
    {
      date: "2009-10-23",
      country: "US",
      city: "New York",
      state: "NY",
      venue: "Swedish Consulate",
    },
    {
      date: "2009-10-23",
      country: "US",
      city: "New York",
      state: "NY",
      venue: "Scandinavian House",
    },
    {
      date: "2009-10-22",
      country: "US",
      city: "Brooklyn",
      state: "NY",
      venue: "B Conte",
    },
    {
      date: "2009-10-21",
      country: "US",
      city: "New York",
      state: "NY",
      venue: "Sirius XM Radio",
    },
    {
      date: "2009-10-20",
      country: "US",
      city: "New York",
      state: "NY",
      venue: "CMJ @ The Livingroom",
    },
    {
      date: "2009-10-17",
      country: "SWE",
      city: "Arboga",
      state: null,
      venue: "Herfallet",
    },
    {
      date: "2009-10-08",
      country: "SWE",
      city: "Hultsfred",
      state: null,
      venue: "Metropol",
    },
    {
      date: "2009-09-26",
      country: "SWE",
      city: "Norrköping",
      state: null,
      venue: "Saliga Munken",
    },
    {
      date: "2009-09-18",
      country: "SWE",
      city: "TV4",
      state: null,
      venue: "Nyhetsmorgon",
    },
    {
      date: "2009-09-08",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Nalen",
    },
    {
      date: "2009-09-01",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Göta Källare",
    },
    {
      date: "2009-08-29",
      country: "SWE",
      city: "Borlänge",
      state: null,
      venue: "Cafe Peace&Love",
    },
    {
      date: "2009-08-28",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Teater Pero",
    },
    {
      date: "2009-08-26",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Louie Louie",
    },
    {
      date: "2009-08-15",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Ung08 Festival",
    },
    {
      date: "2009-07-17",
      country: "SWE",
      city: "Visby",
      state: null,
      venue: "Bolaget",
    },
    {
      date: "2009-07-16",
      country: "SWE",
      city: "Gotland",
      state: null,
      venue: "Toftagården",
    },
    {
      date: "2009-07-03",
      country: "SWE",
      city: "Arvika",
      state: null,
      venue: "Arvikafestivalen",
    },
    {
      date: "2009-06-27",
      country: "SWE",
      city: "Borlänge",
      state: null,
      venue: "Peace&Love @ Cafe Peace&Love",
    },
    {
      date: "2009-06-26",
      country: "SWE",
      city: "Borlänge",
      state: null,
      venue: "Peace&Love @ Orion",
    },
    {
      date: "2009-06-26",
      country: "SWE",
      city: "Borlänge",
      state: null,
      venue: "Peace&Love @ Groove",
    },
    {
      date: "2009-06-26",
      country: "SWE",
      city: "Borlänge",
      state: null,
      venue: "Peace&Love @ Kupolen",
    },
    {
      date: "2009-06-17",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Södra Teatern",
    },
    {
      date: "2009-06-07",
      country: "SWE",
      city: "Eskilstuna",
      state: null,
      venue: "Eskilstuna 350 år",
    },
    {
      date: "2009-05-14",
      country: "UK",
      city: "Brighton",
      state: null,
      venue: "The Great Escape @ King&Queen",
    },
    {
      date: "2009-05-13",
      country: "UK",
      city: "London",
      state: null,
      venue: "The Bedford",
    },
    {
      date: "2009-05-12",
      country: "UK",
      city: "London",
      state: null,
      venue: "The Fly",
    },
    {
      date: "2009-03-21",
      country: "US",
      city: "Austin",
      state: "TX",
      venue: "SXSW @ Wave Rooftop",
    },
    {
      date: "2009-03-20",
      country: "US",
      city: "Austin",
      state: "TX",
      venue: "SXSW @ Habana Calle 6",
    },
    {
      date: "2009-01-28",
      country: "SWE",
      city: "Gothenburg",
      state: null,
      venue: "Kontiki",
    },
    {
      date: "2009-01-15",
      country: "SWE",
      city: "Gothenburg",
      state: null,
      venue: "Jazzhuset",
    },
    {
      date: "2008-11-30",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Mosebacke",
    },
    {
      date: "2008-11-18",
      country: "NO",
      city: "Oslo",
      state: null,
      venue: "The New Union",
    },
    {
      date: "2008-10-25",
      country: "US",
      city: "New York",
      state: "NY",
      venue: "CMJ @ The Livingroom",
    },
    {
      date: "2008-10-16",
      country: "US",
      city: "Los Angeles",
      state: "CA",
      venue: "Genghis Cohen",
    },
    {
      date: "2008-10-03",
      country: "US",
      city: "New York",
      state: "NY",
      venue: "Crash Mansion",
    },
    {
      date: "2008-09-25",
      country: "SWE",
      city: "Eskilstuna",
      state: null,
      venue: "Cafe Sylvia",
    },
    {
      date: "2008-09-16",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Alcazar",
    },
    {
      date: "2008-07-26",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: `Fritz's Corner`,
    },
    {
      date: "2008-05-29",
      country: "UK",
      city: "London",
      state: null,
      venue: "Water Rats Theatre",
    },
    {
      date: "2008-04-26",
      country: "SWE",
      city: "Eskilstuna",
      state: null,
      venue: "Lokomotivet",
    },
    {
      date: "2008-04-06",
      country: "US",
      city: "Los Angeles",
      state: "CA",
      venue: "Tangier",
    },
    {
      date: "2008-04-02",
      country: "US",
      city: "New York",
      state: "NY",
      venue: "Rockwood Music Hall",
    },
    {
      date: "2008-03-20",
      country: "SWE",
      city: "Eskilstuna",
      state: null,
      venue: "Lotus Lounge",
    },
    {
      date: "2007-08-10",
      country: "SWE",
      city: "Falun",
      state: null,
      venue: "Kopparhatten",
    },
    {
      date: "2007-06-05",
      country: "SWE",
      city: "Eskilstuna",
      state: null,
      venue: "Rademacher",
    },
    {
      date: "2007-03-30",
      country: "SWE",
      city: "Eskilstuna",
      state: null,
      venue: "Raw",
    },
    {
      date: "2007-01-20",
      country: "SWE",
      city: "Lund",
      state: null,
      venue: "Mejeriet",
    },
    {
      date: "2007-01-16",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Alcazar",
    },
    {
      date: "2006-12-03",
      country: "US",
      city: "Boston",
      state: "MA",
      venue: "Paradise Lounge",
    },
    {
      date: "2006-12-01",
      country: "US",
      city: "New York",
      state: "NY",
      venue: "R&R",
    },
    {
      date: "2006-11-30",
      country: "US",
      city: "New York",
      state: "NY",
      venue: "The Knitting Factory",
    },
    {
      date: "2006-11-26",
      country: "US",
      city: "Philadelphia",
      state: "PA",
      venue: `Sal's`,
    },
    {
      date: "2006-11-16",
      country: "US",
      city: "Syracuse",
      state: "NY",
      venue: "Mezzanote Lounge",
    },
    {
      date: "2006-11-15",
      country: "US",
      city: "Saratoga Springs",
      state: "NY",
      venue: `King's Tavern`,
    },
    {
      date: "2006-11-14",
      country: "US",
      city: "Oneonta",
      state: "NY",
      venue: "Room 212",
    },
    {
      date: "2006-08-26",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Debaser",
    },
    {
      date: "2006-08-25",
      country: "SWE",
      city: "Eskilstuna",
      state: null,
      venue: "Sommarens Sista Suck",
    },
    {
      date: "2006-08-11",
      country: "SWE",
      city: "Emmaboda",
      state: null,
      venue: "Emmabodafestivalen",
    },
    {
      date: "2006-06-21",
      country: "US",
      city: "New York",
      state: "NY",
      venue: `CBGB's`,
    },
    {
      date: "2006-06-07",
      country: "US",
      city: "New York",
      state: "NY",
      venue: "Rockwood Music Hall",
    },
    {
      date: "2006-06-06",
      country: "US",
      city: "New York",
      state: "NY",
      venue: "The Coda",
    },
    {
      date: "2006-05-28",
      country: "US",
      city: "New York",
      state: "NY",
      venue: "The Livingroom",
    },
    {
      date: "2006-05-17",
      country: "US",
      city: "New York",
      state: "NY",
      venue: `Mo Pitken's`,
    },
    {
      date: "2006-02-21",
      country: "SWE",
      city: "Stockholm",
      state: null,
      venue: "Stampen",
    },
    {
      date: "2005-12-14",
      country: "SWE",
      city: "Kalmar",
      state: null,
      venue: "Söderport",
    },
    {
      date: "2005-07-11",
      country: "SWE",
      city: "Visby",
      state: null,
      venue: "Skenholms",
    },
  ],
}

// export const compDates = [
//   'Mar 1 2019 (SWE) Stockholm – Scala Theater',
//   'Jul 29 2017 (SWE) Eskilstuna – Torshälla 700 Festival',
//   'Dec 11 2014 (SWE) Uppsala – Kalmar Nation',
//   'May 3 2012 (SWE) Stockholm – Scandic Grand Central',
//   'Dec 13 2011 (SWE) Stockholm – Musslan',
//   'Nov 11 2011 (SWE) Stockholm – Wirströms',
//   'Aug 26 2011 (SWE) Borlänge – Café Peace&Love',
//   'Aug 25 2011 (SWE) Stockholm – Trädgården',
//   'Jul 2 2011 (SWE) Borlänge – Peace&Love Festival',
//   'Feb 16 2011 (SWE) Stockholm – Musslan',
//   'Feb 14 2011 (SWE) Stockholm – Hjerta',
//   'Oct 30 2010 (SWE) Eskilstuna – Stadsteatern',
//   'Oct 18 2010 (SWE) Stockholm – Releaseparty @ Lilla Hotellbaren',
//   'Oct 14 2010 (SWE) Stockholm – Rocket FM Radio',
//   'Sep 21 2010 (US) Los Angeles, CA – Silverlake Lounge',
//   'Sep 12 2010 (US) Fairfax, CA – 19 Broadway',
//   'Sep 10 2010 (US) San Francisco, CA – Brainwash',
//   'Jul 3 2010 (SWE) Borlänge – Peace&Love @ Gaffa',
//   'Jul 3 2010 (SWE) Borlänge – Peace&Love @ Cozmos',
//   'Jul 3 2010 (SWE) Borlänge – Peace&Love @ Cafe Peace&Love',
//   'Jul 2 2010 (SWE) Leksand – Skutan',
//   'Jun 8 2010 (SWE) Stockholm – Musslan',
//   'Dec 26 2009 (SWE) Eskilstuna – Raw',
//   'Nov 4 2009 (SWE) Stockholm – Södra Teatern',
//   'Nov 3 2009 (FIN) Helsinki - Semifinal',
//   'Nov 3 2009 (FIN) Helsinki - Radio Helsinki',
//   'Oct 29 2009 (US) Los Angeles, CA – The Mint',
//   'Oct 28 2009 (US) Los Angeles, CA – Room 5',
//   'Oct 23 2009 (US) New York, NY – Swedish Consulate',
//   'Oct 23 2009 (US) New York, NY – Scandinavian House',
//   'Oct 22 2009 (US) Brooklyn, NY – B Conte',
//   'Oct 21 2009 (US) New York, NY – Sirius XM Radio',
//   'Oct 20 2009 (US) New York, NY – CMJ @ The Livingroom',
//   'Oct 17 2009 (SWE) Arboga – Herfallet',
//   'Oct 8 2009 (SWE) Hultsfred – Metropol',
//   'Sep 26 2009 (SWE) Norrköping – Saliga Munken',
//   'Sep 18 2009 (SWE) TV4 – Nyhetsmorgon',
//   'Sep 8 2009 (SWE) Stockholm – Nalen',
//   'Sep 1 2009 (SWE) Stockholm – Göta Källare',
//   'Aug 29 2009 (SWE) Borlänge – Cafe Peace&Love',
//   'Aug 28 2009 (SWE) Stockholm - Teater Pero',
//   'Aug 26 2009 (SWE) Stockholm – Louie Louie',
//   'Aug 15 2009 (SWE) Stockholm – Ung08',
//   'Jul 17 2009 (SWE) Visby – Bolaget',
//   'Jul 16 2009 (SWE) Gotland – Toftagården',
//   'Jul 3 2009 (SWE) Arvika – Arvikafestivalen',
//   'Jun 27 2009 (SWE) Borlänge – Peace&Love @ Cafe Peace&Love',
//   'Jun 26 2009 (SWE) Borlänge – Peace&Love @ Orion',
//   'Jun 26 2009 (SWE) Borlänge – Peace&Love @ Groove',
//   'Jun 26 2009 (SWE) Borlänge – Peace&Love @ Kupolen',
//   'Jun 17 2009 (SWE) Stockholm – Södra Teatern',
//   'Jun 7 2009 (SWE) Eskilstuna – Eskilstuna 350 år',
//   'May 14 2009 (UK) Brighton – The Great Escape @ King&Queen',
//   'May 13 2009 (UK) London – The Bedford',
//   'May 12 2009 (UK) London – The Fly',
//   'Mar 21 2009 (US) Austin, TX – SXSW @ Wave Rooftop',
//   'Mar 20 2009 (US) Austin, TX – SXSW @ Habana Calle 6',
//   'Jan 28 2009 (SWE) Gothenburg – Kontiki',
//   'Jan 15 2009 (SWE) Gothenburg – Jazzhuset',
//   'Nov 30 2008 (SWE) Stockholm – Mosebacke',
//   'Nov 18 2008 (NO) Oslo – The New Union',
//   'Oct 25 2008 (US) New York, NY – CMJ @ The Livingroom',
//   'Oct 16 2008 (US) Los Angeles, CA – Genghis Cohen',
//   'Oct 3 2008 (US) New York, NY – Crash Mansion',
//   'Sep 25 2008 (SWE) Eskilstuna – Cafe Sylvia',
//   'Sep 16 2008 (SWE) Stockholm – Alcazar',
//   'Jul 26 2008 (SWE) Stockholm – Fritz’s Corner',
//   'May 29 2008 (UK) London – Water Rats Theatre',
//   'Apr 26 2008 (SWE) Eskilstuna – Lokomotivet',
//   'Apr 6 2008 (US) Los Angeles, CA – Tangier',
//   'Apr 2 2008 (US) New York, NY – Rockwood Music Hall',
//   'Mar 20 2008 (SWE) Eskilstuna – Lotus Lounge',
//   'Aug 10 2007 (SWE) Falun – Kopparhatten',
//   'Jun 5 2007 (SWE) Eskilstuna – Rademacher',
//   'Mar 30 2007 (SWE) Eskilstuna – Raw',
//   'Jan 20 2007 (SWE) Lund – Mejeriet',
//   'Jan 16 2007 (SWE) Stockholm – Alcazar',
//   'Dec 3 2006 (US) Boston, MA – Paradise Lounge',
//   'Dec 1 2006 (US) New York, NY – R&R',
//   'Nov 30 2006 (US) New York, NY – The Knitting Factory',
//   'Nov 26 2006 (US) Philadelphia, PA – Sal’s',
//   'Nov 16 2006 (US) Syracuse, NY – Mezzanote Lounge',
//   'Nov 15 2006 (US) Saratoga Springs, NY – King’s Tavern',
//   'Nov 14 2006 (US) Oneonta, NY – Room 212',
//   'Aug 26 2006 (SWE) Stockholm – Debaser',
//   'Aug 25 2006 (SWE) Eskilstuna – Sommarens Sista Suck',
//   'Aug 11 2006 (SWE) Emmaboda – Emmabodafestivalen',
//   'Jun 21 2006 (US) New York, NY – CBGB’s',
//   'Jun 7 2006 (US) New York, NY – Rockwood Music Hall',
//   'Jun 6 2006 (US) New York, NY – The Coda',
//   'May 28 2006 (US) New York, NY – The Livingroom',
//   'May 17 2006 (US) New York, NY – Mo Pitken’s',
//   'Feb 21 2006 (SWE) Stockholm - Stampen',
//   'Dec 14 2005 (SWE) Kalmar – Söderport',
//   'Jul 11 2005 (SWE) Visby – Skenholms',
// ]
