export default {
  menu: [
    {
      title: 'About',
      url: '/',
    },
    {
      title: 'News',
      url: '/news',
    },
    {
      title: 'Music',
      url: '/music',
    },
    {
      title: 'Videos',
      url: '/videos',
    },
    {
      title: 'Tour',
      url: '/tour',
    },
    // {
    //   title: 'Merch',
    //   url: '/merch',
    // },
  ],
  submenu: [
    {
      title: 'Contact',
      url: '/contact',
    },
    {
      title: 'Press',
      url: '/press',
    },
  ],
}
