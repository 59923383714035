// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-news-single-page-js": () => import("../src/templates/newsSinglePage.js" /* webpackChunkName: "component---src-templates-news-single-page-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-js": () => import("../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-news-js": () => import("../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-press-js": () => import("../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-tour-js": () => import("../src/pages/tour.js" /* webpackChunkName: "component---src-pages-tour-js" */),
  "component---src-pages-videos-js": () => import("../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */)
}

